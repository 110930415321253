import { ChevronLeftIcon, TrendingUpIcon, ShareIcon, DocumentTextIcon } from "@heroicons/react/solid";
import { useContext, useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import Container from "../../components/Container";
import { AuthContext } from "../../context/auth-context";
import "react-circular-progressbar/dist/styles.css";
import ProgressProvider from "../../components/ProgressProvider";
import { generateTextForScore } from "../../business/Helpers";
import parse from "html-react-parser";
import Tooltip from "../../components/Tooltip"
import { showGuideMe } from "../../business/showGuideMe";
import GuideMe from "../../modals/GuideMe";
import ConnectMe from "../../modals/ConnectMe";
import IntroduceMe from "../../modals/IntroduceMe";
import Notification from "../../modals/Notification";
import Settings from "../../modals/Settings";
import { ArrowCircleUpIcon, PlusIcon } from "@heroicons/react/outline";
import SpecialSale from "../../modals/SpecialSale";

export default function UserResult(props) {
  const auth = useContext(AuthContext);
  const [userResult, setUserResult] = useState(null);
  const [userData, setUserData] = useState(null);
  const [percentage, setPercentage] = useState(0);
  const [error, setError] = useState(false);
  const [openGuideMe, setOpenGuideMe] = useState(false);
  const [openConnectMe, setOpenConnectMe] = useState(false);
  const [openIntroduceMe, setOpenIntroduceMe] = useState(false);
  const [showNoti, setShowNoti] = useState(false);
  const [openSale, setOpenSale] = useState(false);

  // console.log(userResult.migrationCheck.title);

  const { id } = useParams();

  const loadUserResult = () => {
    fetch(`/api/user-results/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        return Promise.reject(res);
      })
      .then((userResult) => {
        setUserResult(userResult);
        setPercentage(
          Math.round((userResult.points * 100) / userResult.maxPoints)
        );
      })
      .catch((err) => {
        if (err.status === 401) {
          auth.logout();
        } else {
          setError(true);
        }
      });
  };

  const sendRequest = () => {
    fetch(`/api/user`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        return Promise.reject(res);
      })
      .then((result) => {
        setUserData(result);
      })
      .catch((err) => {
        if (err.status === 401) {
          auth.logout();
        }
      });
  };

  const navigate = useNavigate();
  const packageInclude = [
  { title: '2 detailed checks', tooltip: 'The number of additional detailed checks you can complete.' },
  { title: 'Full version', tooltip: 'Get full access to all the remarks and recommendations on how to improve your chances.' },
  { title: 'Detailed results', tooltip: 'Upon completion of a detailed migration check, you’ll get a detailed result' },
  { title: 'Guide-me feature', tooltip: 'See all the steps and links in the step-by-step guide.' },
  { title: 'Connect-me feature', tooltip: 'Use the Connect-me button, if it is included in your category.' },
  { title: 'Introduce-me feature', tooltip: 'Use the Introduce-me button, if it is included in your category' },
  { title: 'Save and continue later', tooltip: 'You can discontinue your check and resume later' },
  { title: 'Technical support', tooltip: 'We’ll gladly respond to each email you send us.' },
  ]

  const packageIncludeshort = [
    { title: 'Read everything', tooltip: 'Upon completion of a detailed migration check, you’ll get a detailed result' },
    { title: 'All Premium features', tooltip: 'Upon completion of a detailed migration check, you’ll get a detailed result' },
    ]


  useEffect(() => {
    loadUserResult();
    sendRequest();
  }, []);
  // console.log(userResult);
  // console.log(userResult?.migrationCheck.title=='Work' ? userResult?.questions[0].answers[0].selected : null);

  useEffect(() => {
    setTimeout(() => {
      // console.log("timer")
      setOpenSale(true);
    }, 15000);
  }, [])

  return (
    <>
      <Container>
        {userResult && (
          <div className="py-10">
            <header>
              <Link to={"/"} className="group">
                <div className="flex items-center">
                  <ChevronLeftIcon
                    className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                    Back
                  </span>
                </div>
              </Link>
              <h1 className="text-3xl font-bold leading-tight text-gray-900">
                Your result
              </h1>
            </header>
            <main>
              <div className="lg:grid lg:grid-cols-12 lg:gap-8 mt-8">
                <div className="lg:col-span-3 mb-6">
                  <div className="bg-white shadow rounded-lg px-4 py-5 sm:px-6">
                    <div className="overflow-hidden text-center lg:text-left max-w-[296px] mx-auto">
                      <div className="p-6 lg:p-2 xl:p-6 lg:mb-4 xl:mb-0 mx-auto">
                        {console.log(userResult.points)}
                        {userResult && (
                          <ProgressProvider valueStart={0} valueEnd={percentage}>
                            {(value) => (
                              <CircularProgressbar
                                value={value}
                                // text={`${value}%`}
                                text={`${generateTextForScore(
                                  userResult.points,
                                  userResult.migrationCheck.title
                                )}`}
                                styles={buildStyles({
                                  pathColor: `#ff6f91`,
                                  textColor: "#ff6f91",
                                  trailColor: "#e5e7eb",
                                  textSize: '16px',
                                })}
                              />
                            )}
                          </ProgressProvider>
                        )}
                      </div>
                      <h3 className="text-xl font-medium text-gray-900 capitalize">
                        {userResult &&
                          generateTextForScore(
                            userResult.points,
                            userResult.migrationCheck.title
                          )}
                      </h3>
                      <p className="mt-1 text-sm text-gray-500">
                        {userResult &&
                          userResult.migrationCheck.description?.replaceAll(
                            "{result}",
                            generateTextForScore(userResult.points, userResult.migrationCheck.title)
                          )}
                      </p>
                    </div>
                    {showGuideMe(userResult.migrationCheck.title) && <div className="grid justify-items-center">
                      <button
                        type="button"
                        className="w-64 sm:w-40 2xl:w-64 inline-flex items-center mt-7 justify-center font-medium text-sm leading-none px-6 py-[17px] text-white bg-primary-600 hover:bg-primary-700 rounded-lg duration-150 focus:ring-4 ring-primary-100 ring-opacity-40 shake-animation disabled:animate-none"
                        onClick={() => setOpenGuideMe(true)}
                        disabled={userResult.points <= 0}
                      >
                        <TrendingUpIcon
                          className="-ml-0.5 mr-2 h-4 w-4"
                          aria-hidden="true"
                        />
                        Guide me
                      </button>
                    </div>}
                    {(userResult?.migrationCheck.title == 'Work' && userResult?.questions[0].answers[1].selected) && <div className="grid justify-items-center">
                      <button
                        type="button"
                        className="w-64 sm:w-40 2xl:w-64 inline-flex items-center my-7 justify-center font-medium text-sm leading-none px-6 py-[17px] text-white bg-primary-600 hover:bg-primary-700 rounded-lg duration-150 focus:ring-4 ring-primary-100 ring-opacity-40 disabled:animate-none"
                        onClick={() => { setOpenConnectMe(true) }}
                        disabled={userResult.points <= 79 || !userData.premium}
                      >
                        <ShareIcon
                          className="-ml-0.5 mr-2 h-4 w-4"
                          aria-hidden="true"
                        />
                        Connect me
                      </button>
                    </div>}
                    {userResult?.migrationCheck.title == 'Apprenticeship' && <div className="grid justify-items-center">
                      <button
                        type="button"
                        className="w-64 sm:w-40 2xl:w-64 inline-flex items-center my-7 justify-center font-medium text-sm leading-none px-6 py-[17px] text-white bg-primary-600 hover:bg-primary-700 rounded-lg duration-150 focus:ring-4 ring-primary-100 ring-opacity-40 disabled:animate-none"
                        onClick={() => { setOpenIntroduceMe(true) }}
                      >
                        <ShareIcon
                          className="-ml-0.5 mr-2 h-4 w-4"
                          aria-hidden="true"
                        />
                        Introduce me
                      </button>
                    </div>}
                  </div>
                </div>
                <div className="lg:col-span-9">
                  {userResult &&
                    userResult.questions.map((question, index) => (
                      <div
                        key={question.id}
                        className="bg-white shadow rounded-lg overflow-hidden mb-6 "
                      >
                        <div className="px-4 py-5 sm:px-6 sm:px-6 border-b border-gray-200">
                          <div className="flex items-center">
                            <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                              <span className="text-gray-500 group-hover:text-gray-900">
                                {index + 1}
                              </span>
                            </span>
                            <div className="ml-4">
                              <h3 className="text-lg leading-6 font-medium text-gray-900">
                                {question.title}
                              </h3>
                              {question.description && <p className="mt-1 text-sm text-gray-500">
                                {parse(question.description)}
                              </p>}
                              <div className="flex flex-wrap gap-1 mt-1">
                                {question.answers.map((answer) => (
                                  <div
                                    key={answer.id}
                                    className={`inline-block px-2.5 py-0.5 rounded-full text-xs font-medium ${answer.selected
                                      ? "bg-primary-500 text-white"
                                      : "bg-gray-200 text-gray-800"
                                      }`}
                                  >
                                    {answer.title}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="px-4 py-5 sm:px-6">
                          <div className="ml-14">
                            {question.answers
                              .filter((answer) => answer.selected === true)
                              .map((answer) => (
                                <div key={answer.id}>
                                  <section className="font-semibold mb-2">Remark and recommendation:</section>
                                  <span className="text-sm leading-6">
                                    {parse(answer.description)}
                                    {/* <button
                                      type="button"
                                      style={{position: "relative"}}
                                      className="paywall mt-2 w-full inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                                      onClick={() => {
                                        navigate("/buy/basic");
                                      }}
                                    >
                                    <div>
                                    <p className="text-xl my-2">Go Premium</p>
                                    <p>Get access to all your individuell tips and find out about your next steps</p>
                                    </div>
                                  </button> */}
                                    {/* <Link
                                    to="/upgrade"
                                    style={{position: "relative"}}
                                    // onClick={() => window.location.href="/upgrade"}
                                    className="paywall w-full mt-3 inline-flex items-center justify-center px-6 py-3 border border-gray-300 text-sm font-medium rounded-lg border-primary-500 bg-white hover:bg-gray-50 focus:outline-none"
                                  >
                                    <ArrowCircleUpIcon
                                      className="-ml-0.5 mr-2 h-4 w-4 text-primary-500"
                                      aria-hidden="true"
                                    />
                                    <span className="bg-clip-text text-transparent bg-primary-linear">
                                      Upgrade to Premium
                                    </span>
                                  </Link> */}
                                    {/* PREMIUM Paywall offer */}
                                    {index === 0 && !userData?.premium &&
                                    <div className="paywall mt-2 mx-auto max-w-2xl rounded-xl ring-1 ring-gray-200 2xl:mx-0 2xl:flex xl:max-w-none" style={{position: "relative", marginLeft: "-55px"}}>
                                      <div className="p-8 sm:p-10 2xl:flex-auto">
                                        <div className="flex gap-x-3">
                                          <h3 className="text-2xl font-bold tracking-tight text-gray-900">Upgrade</h3>
                                          <DocumentTextIcon className="h-6 w-6 text-primary-500" />
                                        </div>
                                        <div className="mt-6 text-base leading-7 text-gray-600 flex gap-x-2">
                                          Get full access to all your personal recommendations, and use the special features such as "Connect me" and "Introduce me" to fast track moving to Germany!
                                        </div>
                                        <div className="mt-10 flex items-center gap-x-4">
                                          <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">What’s included</h4>
                                          <div className="h-px flex-auto bg-gray-100" />
                                        </div>
                                        <ul
                                          role="list"
                                          className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
                                        >
                                          {packageInclude.map((x) => (
                                            <li key={x.title} className="flex gap-x-3">
                                              <img className="w-6 h-6" width={24} height={24} alt="" src="/assets/images/Check.svg"></img>
                                              {x.title}
                                              <Tooltip>{x.tooltip}</Tooltip>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                      <div className="mt-2 2xl:p-20 xl:px-32 xl:pb-6 2xl:mt-0 2xl:w-full 2xl:max-w-md 2xl:flex-shrink-0">
                                        <div className="rounded-xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 2xl:flex 2xl:flex-col 2xl:justify-center">
                                          <div className="mx-auto max-w-xs px-8">
                                            <p className="text-xs font-semibold text-gray-600">One time fee, no other costs</p>
                                            <p className="mt-6 flex items-baseline justify-center gap-x-2">
                                              <span className="text-7xl font-bold tracking-tight text-gray-900">5</span>
                                              <div><img style={{ marginBottom: "35px", marginLeft: "5px" }} alt="" src="/assets/images/euro.svg"></img></div>
                                            </p>
                                            <button
                                              type="button"
                                              className="mt-10 w-full inline-flex items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                                              onClick={() => {
                                                navigate("/buy/standard");
                                              }}
                                            >
                                              Upgrade to Standard
                                            </button>
                                            <p className="mt-6 text-xs leading-5 text-gray-600">
                                            get new dashboard access for 6 months
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    }
                                      {/* PREMIUM short */}
                                      {/* <div className="paywall mt-2 mx-auto max-w-2xl rounded-xl ring-1 ring-gray-200 2xl:mx-0 2xl:flex xl:max-w-none" style={{position: "relative", marginLeft: "-55px"}}>
                                      <div className="p-8 2xl:p-6 sm:p-10 2xl:flex-auto">
                                        <div className="flex gap-x-3">
                                          <h3 className="text-2xl font-bold tracking-tight text-gray-900">Go Premium</h3>
                                          <DocumentTextIcon className="h-6 w-6 text-primary-500" />
                                        </div>
                                        <ul
                                          role="list"
                                          className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
                                        >
                                          {packageIncludeshort.map((x) => (
                                            <li key={x.title} className="flex gap-x-3">
                                              <img className="w-6 h-6" width={24} height={24} alt="" src="/assets/images/Check.svg"></img>
                                              {x.title}
                                              <Tooltip>{x.tooltip}</Tooltip>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                      <div className="mt-2 2xl:mt-0 2xl:w-full 2xl:max-w-md 2xl:flex">
                             
                                          <div className="rounded-xl m-auto bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 2xl:mr-3">
                                            <div className="mx-auto max-w-xs px-14">
                                              <button
                                                type="button"
                                                className="w-full inline-flex items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                                                onClick={() => {
                                                  navigate("/buy/basic");
                                                }}
                                              >
                                                <p className="px-8">I want premium</p>
                                              </button>
                        
                                            </div>
                                          </div>
                                    
                                      </div>
                                    </div> */}

                                      {/* PREMIUM short */}
                                      {index >=1 && !userData?.premium &&
                                      <div className="paywall bg-gray-50 mt-2 mx-auto max-w-2xl rounded-xl ring-1 ring-gray-200 2xl:mx-0 2xl:flex xl:max-w-none" style={{position: "relative", marginLeft: "-55px"}}>
                                      <div className="py-4 2xl:p-4 sm:p-4 2xl:flex-auto">
                                        <div className="flex w-full mx-auto place-content-center">
                                          <p className="text-sm xl:text-xl font-bold tracking-tight text-gray-500">Get the full version to continue reading</p>
                                          <DocumentTextIcon className="h-5 w-5 text-primary-500 ml-2" />
                                        </div>
                                     
                                      </div>
                        
                                      </div>
                                      }


                                    {/* <div className="bg-white">
                                      <div className="mx-auto">

                                        <div className="mx-auto mb-16 max-w-2xl rounded-3xl ring-1 lg:mx-0 lg:flex lg:max-w-none" style={{ boxShadow: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(17, 24, 39, 0.05) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0) 0px 0px 0px 0px' }}>
                                          <div className="p-8 sm:p-10 lg:flex-auto">
                                            <h2 className="text-2xl font-bold tracking-tight text-gray-900">{title}</h2>
                                            <p className="mt-6 text-base leading-7 text-gray-600">
                                              {content}
                                            </p>
                                            <div className="mt-10 flex items-center gap-x-4">
                                              <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">What to expect</h4>
                                              <div className="h-px flex-auto bg-gray-100" style={{ backgroundColor: 'rgb(243 244 246)' }}></div>
                                            </div>
                                            <ul
                                              role="list"
                                              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:gap-6"
                                            >
                                              {bulletpoints.map((feature, index) => (
                                                <li key={index} className="flex gap-x-3">
                                                  <img className="w-6 h-6" width={24} height={24} alt="" src="images/Check.svg"></img>
                                                  {feature}
                                                </li>
                                              ))}
                                            </ul>
                                          </div>
                                          <div className="-mt-2 p-4 md:p-10 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0 justify-center">
                                            <div className="h-full flex flex-col-reverse">
                                              <div className="rounded-2xl bg-gray-50 py-8 text-center ring-1 ring-inset lg:flex lg:flex-col lg:justify-center lg:py-16" style={{ backgroundColor: 'rgb(251 252 253)', boxShadow: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(17, 24, 39, 0.05) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0) 0px 0px 0px 0px' }}>
                                                <div className="mx-auto max-w-xs px-8">
                                                  <p className="text-base font-semibold text-gray-600">The duration of this service is</p>
                                                  <p className="mt-4 flex items-baseline justify-center gap-x-2">
                                                    <span className="text-5xl font-bold tracking-tight text-gray-900">{duration}</span>
                                                    <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">days</span>
                                                  </p>
                                                  <a href={link} className="mt-5 hover:no-underline inline-block items-center rounded-lg px-6 text-sm font-bold py-[16px] bg-linear-primary-3 text-white hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Request Service</a>
                                                  <p className="mt-6 text-xs leading-5 text-gray-600">
                                                    {hint}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div> */}
                                  </span>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </main>
          </div>
        )}
        {error && <>Something went wrong</>}
      </Container>
      {userResult && (<GuideMe openGuideMe={openGuideMe} setOpenGuideMe={setOpenGuideMe} userResult={userResult} userData={userData} />)}
      <ConnectMe openConnectMe={openConnectMe} setOpenConnectMe={setOpenConnectMe} setShowNoti={setShowNoti} />
      <IntroduceMe openConnectMe={openIntroduceMe} setOpenConnectMe={setOpenIntroduceMe} setShowNoti={setShowNoti} />
      {/* <Notification setOpen= {setShowNoti} open= {showNoti} /> */}
      <Notification setShowNoti={setShowNoti} showNoti={showNoti} />
      {/* {userData?.quota === 0 && <Sale openSale={openSale} setOpenSale={setOpenSale} /> } */}
      {!userData?.premium && <SpecialSale openSale={openSale} setOpenSale={setOpenSale} />} 
    </>
  );
}
