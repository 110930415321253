import { KeyIcon, MailIcon, XCircleIcon, InformationCircleIcon, CheckCircleIcon, UserIcon, PencilIcon } from "@heroicons/react/outline";
import React, { useEffect, useReducer, useContext, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/auth-context";
import { Link } from "react-router-dom";
import { PhoneIcon, CalendarIcon } from "@heroicons/react/solid";

import {
  validate,
  VALIDATOR_REQUIRE,
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
} from "../../form-validation/validators";

const loginEmailReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators),
      };
    case "TOUCH":
      return { ...state, isTouched: true };
    default:
      return state;
  }
};

const messageReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators),
      };
    case "TOUCH":
      return { ...state, isTouched: true };
    default:
      return state;
  }
};

const qalificationReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators),
      };
    case "TOUCH":
      return { ...state, isTouched: true };
    default:
      return state;
  }
};

const languageReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators),
      };
    case "TOUCH":
      return { ...state, isTouched: true };
    default:
      return state;
  }
};

const loginCheckTerms = (state, action) => {
  // console.log(action);
  switch (action.type) {
    case "CHANGE":
      return { ...state, isValid: action.val };
    case "TOUCH":
      return { ...state, isTouched: true };
    default:
      return state;
  }
};

const loginFormReducer = (state, action) => {
  switch (action.type) {
    case "INPUTCHANGE":
      // console.log('We are here')
      let formIsValid = true;
      for (const input in state.inputs) {
        if (input === action.inputId) {
          formIsValid = formIsValid && action.isValid;
        } else {
          formIsValid = formIsValid && state.inputs[input].isValid;
        }
      }
      // console.log(formIsValid)
      return {
        ...state,
        inputs: {
          ...state.inputs,
          [action.inputId]: { value: action.value, isValid: action.isValid },
        },
        isValid: formIsValid,
      };
    default:
      return state;
  }
};

function Onboarding(props) {
  const [page, setPage] = useState(1);
  const [phone, setPhone] = useState("");
  const [age, setAge] = useState("");
  const auth = useContext(AuthContext);
  let navigate = useNavigate();
  const [nameState, dispatch] = useReducer(loginEmailReducer, {
    value: "",
    isValid: false,
    isTouched: false,
  });
  const [checkboxState, dispatchCheckTerms] = useReducer(loginCheckTerms, {
    isValid: false,
    isTouched: false,
  });
  const [messageState, dispatcher] = useReducer(messageReducer, {
    value: "",
    isValid: false,
    isTouched: false,
  });

  const [qualificationState, qdispatcher] = useReducer(qalificationReducer, {
    value: "",
    isValid: false,
    isTouched: false,
  });

  const [languageState, ldispatcher] = useReducer(languageReducer, {
    value: "",
    isValid: false,
    isTouched: false,
  });

  const [loginFormState, dispatching] = useReducer(loginFormReducer, {
    inputs: {
      name: { value: "", isValid: false },
      message: { value: "", isValid: false },
      qualification: { value: "", isValid: false },
      language: { value: "", isValid: false },
      check: { isValid: false },
    },
    isValid: false,
  }); //here: isValid die gesamte Form

  const [error, setError] = useState(false);
  const handleCloseErrorModal = () => {
    setError(null);
  };

  useEffect(() => {
    // console.log('Yes, running!')
    dispatching({
      type: "INPUTCHANGE",
      value: nameState.value,
      isValid: nameState.isValid,
      inputId: "name",
    });
  }, [nameState.value, nameState.isValid]);
  useEffect(() => {
    // console.log('Yes, running!')
    dispatching({
      type: "INPUTCHANGE",
      isValid: checkboxState.isValid,
      inputId: "check",
    });
  }, [checkboxState.isValid]);
  useEffect(() => {
    dispatching({
      type: "INPUTCHANGE",
      value: messageState.value,
      isValid: messageState.isValid,
      inputId: "message",
    });
  }, [messageState.value, messageState.isValid]);
  useEffect(() => {
    dispatching({
      type: "INPUTCHANGE",
      value: qualificationState.value,
      isValid: qualificationState.isValid,
      inputId: "qualification",
    });
  }, [qualificationState.value, qualificationState.isValid]);
  useEffect(() => {
    dispatching({
      type: "INPUTCHANGE",
      value: languageState.value,
      isValid: languageState.isValid,
      inputId: "language",
    });
  }, [languageState.value, languageState.isValid]);

  const emailChangeHandler = (event) => {
    // console.log(event.target.value)
    dispatch({
      type: "CHANGE",
      val: event.target.value,
      validators: [VALIDATOR_REQUIRE(), VALIDATOR_EMAIL()],
    });
  };
  const checkTermsChangeHandler = (event) => {
    dispatchCheckTerms({
      type: "CHANGE",
      val: event.target.checked,
    });
  };

  const phoneChangeHandler = (event) => {
    // console.log(event.target.value)
    setPhone(event.target.value);
  };
  const ageChangeHandler = (event) => {
    // console.log(event.target.value)
    setAge(event.target.value);
  };

  const nameHandler = () => {
    dispatch({ type: "TOUCH" });
  };
  const checkTermsTouchHandler = () => {
    dispatchCheckTerms({ type: "TOUCH" });
  };

  const messageChangeHandler = (event) => {
    dispatcher({
      type: "CHANGE",
      val: event.target.value,
      validators: [VALIDATOR_MINLENGTH(2)],
    });
  };
  const messageTouchHandler = () => {
    dispatcher({ type: "TOUCH" });
  };

  const qualificationChangeHandler = (event) => {
    console.log(event.target.value)
    qdispatcher({
      type: "CHANGE",
      val: event.target.value,
      validators: [VALIDATOR_MINLENGTH(2)],
    });
  };
  const qualificationTouchHandler = () => {
    qdispatcher({ type: "TOUCH" });
  };

  const languageChangeHandler = (event) => {
    ldispatcher({
      type: "CHANGE",
      val: event.target.value,
      validators: [VALIDATOR_MINLENGTH(2)],
    });
  };
  const languageTouchHandler = () => {
    ldispatcher({ type: "TOUCH" });
  };


  const loginFormSubmitHandler = async (event) => {
    event.preventDefault();
    // console.log('This is what we send to the server: ', loginFormState.inputs, phone, age);
    // console.log("Payment" , payment);
    // setPage(2);
    try {
      const response = await fetch("/api/specialservice/onboarding", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: loginFormState.inputs.name.value,
          field: loginFormState.inputs.message.value,
          qualification: loginFormState.inputs.qualification.value,
          language: loginFormState.inputs.language.value,
          check: loginFormState.inputs.check.isValid,
          phone,
          age,
          payment,
        }),
      });
      const responseData = await response.json();

      if (!response.ok) {

        throw new Error(responseData.message);
      }
      setPage(2);
    } catch (error) {
      console.log(error);
      setError(error.message);
    }

  };

  const redirectToHomepage = () => {
    window.location.href = 'https://migration-check.com';
  }

  const [payment, setPayment] = useState(null)
  const onOptionChange = e => {
    setPayment(e.target.value)
  }

  console.log(loginFormState);

  return (
    <>

      <div className="min-h-screen flex bg-gray-100">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          {page === 1 ? (<div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img src="/assets/images/logo.svg" onClick={redirectToHomepage} className="cursor-pointer h-[21px]"/>
              <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                Nice to meet you!
              </h2>
              <h3 className="mb-4 mt-2 italic">
                Before we can start the process of getting you a job offer and work permit, we need more information about you.
              </h3>
            </div>

            {error && (
              <div className="rounded-md bg-red-100 p-4 mt-6">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <XCircleIcon
                      className="h-5 w-5 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-red-800">{error}</p>
                  </div>
                </div>
              </div>
            )}

            <div className="mt-6">
              <div className="mt-6">
                <form onSubmit={loginFormSubmitHandler} className="space-y-8">
                  <div className="space-y-4">
                  {/* Email */}
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-500"
                    >
                      Your email
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <MailIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="true"
                        required
                        className="appearance-none block w-full pl-10 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-rose-500 focus:border-rose-500 sm:text-sm"
                        placeholder="johndoe@gmail.com"
                        onChange={emailChangeHandler}
                        onBlur={nameHandler}
                        value={nameState.value}
                        isInvalid={
                          !nameState.isValid && nameState.isTouched
                        }
                      />
                    </div>
                    {!nameState.isValid && nameState.isTouched && (
                      <p className="mt-1 text-xs text-red-600">
                        Please enter your email
                      </p>
                    )}
                  </div>
                    {/* age */}
                    <div>
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium text-gray-500"
                      >
                        Your age
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <CalendarIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                        <input
                          id="age"
                          name="age"
                          type="text"
                          autoComplete="off"
                          className="appearance-none block w-full pl-10 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-rose-500 focus:border-rose-500 sm:text-sm"
                          placeholder="30 years old"
                          value={age}
                          onChange={ageChangeHandler}
                        />
                      </div>
                    </div>
                  {/* name */}
                  <div>
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium text-gray-500"
                      >
                        Your name
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <UserIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                        <input
                          id="phone"
                          name="phone"
                          type="text"
                          autoComplete="off"
                          className="appearance-none block w-full pl-10 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-rose-500 focus:border-rose-500 sm:text-sm"
                          placeholder="John"
                          value={phone}
                          onChange={phoneChangeHandler}
                        />
                      </div>
                    </div>
                  {/* Textarea */}
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-500"
                    >
                      Field
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <PencilIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <textarea
                        id="textarea"
                        name="textarea"
                        rows={2}
                        required
                        className="appearance-none block w-full pl-10 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-rose-500 focus:border-rose-500 sm:text-sm"
                        placeholder="In which areas are you interested in working?"
                        onChange={messageChangeHandler}
                        onBlur={messageTouchHandler}
                        value={messageState.value}
                        isInvalid={
                          !messageState.isValid && messageState.isTouched
                        }
                      ></textarea>
                    </div>
                    {!messageState.isValid && messageState.isTouched && (
                      <p className="mt-1 text-xs text-red-600">
                        Please enter your fields of interest
                      </p>
                    )}
                    </div>
                  {/* Quali */}
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-500"
                    >
                      Qualification
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <PencilIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <textarea
                        id="textarea"
                        name="textarea"
                        rows={2}
                        required
                        className="appearance-none block w-full pl-10 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-rose-500 focus:border-rose-500 sm:text-sm"
                        placeholder="Your highest education level and work experience"
                        onChange={qualificationChangeHandler}
                        onBlur={qualificationTouchHandler}
                        value={qualificationState.value}
                        isInvalid={
                          !qualificationState.isValid && qualificationState.isTouched
                        }
                      ></textarea>
                    </div>
                    {!qualificationState.isValid && qualificationState.isTouched && (
                      <p className="mt-1 text-xs text-red-600">
                        Please enter your qualifications
                      </p>
                    )}
                    </div>
                  {/* language */}
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-500"
                    >
                      Language competence:
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <PencilIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <textarea
                        id="textarea"
                        name="textarea"
                        rows={2}
                        required
                        className="appearance-none block w-full pl-10 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-rose-500 focus:border-rose-500 sm:text-sm"
                        placeholder="English (advanced), German (beginner)"
                        onChange={languageChangeHandler}
                        onBlur={languageTouchHandler}
                        value={languageState.value}
                        isInvalid={
                          !languageState.isValid && languageState.isTouched
                        }
                      ></textarea>
                    </div>
                    {!languageState.isValid && languageState.isTouched && (
                      <p className="mt-1 text-xs text-red-600">
                        Please enter your language competence
                      </p>
                    )}
                    </div>
                  </div>

                  



                  {/* Payment */}
                  <div className="space-y-3">
                    <label
                      htmlFor=""
                      className="block text-sm font-medium text-gray-500"
                    >
                      How would you prefer to pay for this service? 
                    </label>
                    <div className="flex">
                      {/* One-time payment of 859 € */}
                      <div className="relative flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            type="radio"
                            name="payment"
                            value="Onetime"
                            id="onetime"
                            className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                            checked={payment === "Onetime"}
                            onChange={onOptionChange}
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label htmlFor="" className="text-gray-500 text-xs">
                            One-time payment of 859 €
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="flex">
                      {/* Flexible payment of 200 € per month (Total 1000 €)*/}
                      <div className="relative flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            type="radio"
                            name="payment"
                            value="Sub"
                            id="sub"
                            className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                            checked={payment === "Sub"}
                            onChange={onOptionChange}
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label htmlFor="" className="text-gray-500 text-xs">
                            Flexible payment of 200 € per month (Total 1000 €)
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Checkbox accept Terms of Use */}
                  <div className="relative flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id="check"
                        name="check"
                        type="checkbox"
                        className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                        // checked={checkedTerms}
                        onChange={checkTermsChangeHandler}
                        onBlur={checkTermsTouchHandler}
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label htmlFor="check" className="text-gray-500">
                        By submitting this form, I confirm that I have
                        read and understood the{" "}
                        <a
                          className="text-primary-600 no-underline hover:underline"
                          href="https://migration-check.com/terms/"
                          target="_blank"
                        >
                          terms of use
                        </a>{" "} and the{" "}
                        <a
                          className="text-primary-600 no-underline hover:underline"
                          href="https://migration-check.com/privacy-policy/"
                          target="_blank"
                        >
                          privacy policy
                        </a>{" "}
                        and agree to both of them.
                      </label>
                      <p>
                        {!checkboxState.isValid &&
                          checkboxState.isTouched && (
                            <p className="mt-1 text-xs text-red-600">
                              Please accept our Terms and Conditions to
                              continue!
                            </p>
                          )}
                      </p>
                    </div>
                  </div>


                  <div>
                    <button
                      type="submit"
                      disabled={!loginFormState.isValid || !payment}
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
                    >
                      Request
                    </button>
                  </div>
                </form>


              </div>
            </div>
            {/* Page 2 */}
          </div>) : (
            <>
              <div className="mx-auto w-full max-w-sm lg:w-96">
                <img className="h-[21px]" src="/assets/images/logo.svg" />
                <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                  Work in Germany
                </h2>
                <div className="rounded-md bg-green-100 p-4 mt-6">
                <div className="flex">
                  <div className="flex-shrink-0">
                  <CheckCircleIcon
                    className="flex-shrink-0 h-5 w-5 text-green-500"
                    aria-hidden="true"
                  />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-green-800">Thank you for providing your information!</p>
                  </div>
                </div>
              </div>

              <div className="rounded-md bg-blue-100 p-4 mt-6">
                <div className="flex">
                  <div className="flex-shrink-0">
                  <InformationCircleIcon
                    className="flex-shrink-0 h-5 w-5 text-blue-500"
                    aria-hidden="true"
                  />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-blue-800">We will contact you via email or phone within the next two business days.<br></br> Please also check your spam folder to avoid missing our service offer!</p>
                  </div>
                </div>
              </div>


                {/* <h3 className="mb-4 mt-2">
                  You have successfully registered for the package PERSONAL GUIDE with your email.
                </h3>
                <p className="text-sm text-gray-400">
                  We will notify you as soon as possible as there is a free spot for you.
                </p>
                <br />
                <p className="text-sm text-gray-400">
                  We also send you a confirmation email with your personal queue code.
                </p>
                <br />
                <p className="text-sm text-gray-400">
                  We look forward to seeing you!
                </p> */}
                <br />
                <button
                  type="button"
                  onClick={redirectToHomepage}
                  className="w-full mt-1 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
                >
                  Go back to homepage
                </button>
              </div>
            </>
          )}
        </div>
        <div className="hidden lg:block relative w-0 flex-1 bg-linear-primary-3-1 shadow-inner">
          <img
            className="absolute inset-0 h-full w-full object-contain object-bottom"
            src="/assets/images/loginmigrationcheck.png"
            alt="migrationcheck germany"
          />
        </div>
      </div>
    </>
  );
}

export default Onboarding;
